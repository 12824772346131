<template>
  <dropdown-menu :items="actions($i18n)" :select="onActionSelect">
    <font-awesome-icon icon="ellipsis-h"
  /></dropdown-menu>
</template>

<script>
import get from "lodash/get";
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
} from "../../../../../../config/global";

export default {
  name: "ProductsTableActions",

  data() {},

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    actions(i18n) {
      const canEdit = this.canView(PERMS.ORDERS_EDIT);
      const actions = [
        {
          id: ORDER_ACTION.FILTER,
          text: i18n.t(
            "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.filter"
          ),
        },
        {
          id: ORDER_ACTION.DONE,
          text: i18n.t(
            "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.markAsDelivered"
          ),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: i18n.t(
            "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.markAsNonDelivered"
          ),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: i18n.t(
            "role.orders.manage.ddmp.detail.delivery.table.products.table.actions.openIncident"
          ),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: i18n.t("models.filters.orderStatus.canceled"),
          disabled: !canEdit,
        },
      ];
      return actions;
    },

    getProductName() {
      return get(
        this.data,
        `ppm.lot.productproducer.translations.${this.lang}.name`
      );
    },

    getVariantName() {
      return get(this.data, `ppm.lot.translations.${this.lang}.name`);
    },

    onActionSelect: function (args) {
      const { id } = args.item;

      const pdetails = [this.data.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (id === ORDER_ACTION.FILTER) {
        const label = `${this.getProductName()} - ${this.getVariantName()}`;
        const param = { id: this.data.ppm.id, label: label };
        this.$store.commit("rounds/updateProductId", param);
        EventBus.$emit("orders:applyFilters");
      }

      if (id === ORDER_ACTION.DONE) {
        let nextStatus = filtersMap("ddmp.statusCode.next");
        if (this.data.direct_delivery) {
          nextStatus = filtersMap("ddmiIsland.statusCode.next");
        }
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: nextStatus,
            pdetails,
          })
          .then(onSuccess);
      }

      if (id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.prev"),
            pdetails,
          })
          .then(onSuccess);
      }

      if (id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.PDETAILS, items: [{ ...this.data }] }, //FIXME
          ],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.PDETAILS, items: [{ ...this.data }] }, //FIXME
          ],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
